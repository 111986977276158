<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <!-- post list section -->
    <section v-else class="post__box">
      <div v-for="post in paginatedData" :key="post.id" class="post__element">
        <b-row>
          <b-col cols="12" md="3">
            <router-link :to="{ name: 'BlogPostPage', params: { id: post.uid } }"><div class="post__image" v-lazy:background-image="post.data.image.url"></div></router-link>
          </b-col>
          <b-col cols="12" md="9">
            <h2 class="post__title" v-line-clamp:40="2"><router-link :to="{ name: 'BlogPostPage', params: { id: post.uid } }">{{ $prismic.asText(post.data.title) }}</router-link></h2>
            <p class="post__info">{{ post.data.date | formatDate }} • {{ $prismic.asText(post.data.author) }}</p>
            <p class="post__text" v-line-clamp:32="5" v-html="$prismic.asHtml(post.data.text)"></p>
            <router-link :to="{ name: 'BlogPostPage', params: { id: post.uid } }" class="post__link">Read more »</router-link>
          </b-col>
        </b-row>
      </div>
      <b-row class="pagination">
        <b-col cols="6" sm="4" md="4" order="1" order-sm="0">
          <button class="main-button pagination__button" :disabled="pageNumber === 0" @click.prevent="prevPage" type="button"><img class="post__arrow post__arrow--left" src="@/assets/images/blog/arrow-left-white.svg" alt="arrow" />Previous</button>
        </b-col>
        <b-col cols="12" sm="4" md="4">
          <p class="pagination__text">page {{ this.pageNumber + 1 }} of {{ Math.ceil(this.posts.length / this.size) }}</p>
        </b-col>
        <b-col cols="6" sm="4" md="4" class="text-right" order="2" order-sm="0">
          <button class="main-button pagination__button" :disabled="pageNumber >= pageCount -1" @click.prevent="nextPage" type="button">Next <img class="post__arrow post__arrow--right" src="@/assets/images/blog/arrow-right-white.svg" alt="arrow" /></button>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import MoveTo from 'moveto'
export default {
  name: 'PostList',
  components: {
    Spinner
  },
  data: () => {
    return {
      posts: [],
      cmsContentPending: false,
      pageNumber: 0,
      size: 10
    }
  },
  filters: {
    formatDate (date) {
      const monthNames = ['January', 'February', 'March', 'March', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const originDate = new Date(date)
      return originDate.getDate() + ' ' + monthNames[originDate.getMonth()] + ' ' + originDate.getFullYear()
    }
  },
  methods: {
    async getPosts () {
      // query all posts
      await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'post'),
        {
          orderings: '[my.post.date desc]'
        }
      ).then((response) => {
        this.posts = response.results
        this.cmsContentPending = true
      })
    },
    nextPage () {
      this.pageNumber++
      this.scrollToTargetElement('postListTitle')
    },
    prevPage () {
      this.pageNumber--
      this.scrollToTargetElement('postListTitle')
    },
    scrollToTargetElement (targetElement) {
      const moveTo = new MoveTo({
        tolerance: 100,
        duration: 1000
      })
      const target = document.getElementById(targetElement)
      setTimeout(function () {
        moveTo.move(target)
      }, 200)
    }
  },
  created () {
    this.getPosts()
  },
  computed: {
    pageCount () {
      const pageLength = this.posts.length
      const pageSize = this.size
      return Math.ceil(pageLength / pageSize)
    },
    paginatedData () {
      const start = this.pageNumber * this.size
      const end = start + this.size
      return this.posts.slice(start, end)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./PostList.scss";
</style>
